
import { useManage } from '@/api/services/manage';
import { IonContent, IonHeader, IonTitle, IonToolbar, IonItem, IonLabel, IonButton, modalController, IonFooter, IonSelect, IonSelectOption, onIonViewWillEnter } from "@ionic/vue";
import { defineComponent, reactive, PropType, onMounted } from "vue";
import { UserAppr } from '@/api/services/manage';
export default defineComponent({

  name: "Modal",
  components: { IonContent, IonHeader, IonTitle, IonToolbar, IonItem, IonLabel, IonButton, IonFooter, IonSelect, IonSelectOption }
  ,
  props: {
    apro: {
      type: Object as PropType<UserAppr>
    }
  },
  methods: {
    closeModal() {
      return modalController.dismiss(null, 'cancel');
    },
    submit(userName: any) {
      // console.log(salenm);
      return modalController.dismiss(userName, 'confirm');
    },
    clear() {
      // console.log(salenm);
      return modalController.dismiss(null, 'clear');
    },

  },
  setup(props) {
    const { isConnected, updateAproData, fetchMangerInfo, managerInfo } = useManage();
    fetchMangerInfo();
    const form = reactive({
      erpuser2: '',
      register: props.apro.userId
    });
    onMounted(() => {
      if (managerInfo.value[0]) {
        const adgb2 = managerInfo.value[0];
        form.erpuser2 = adgb2.userName;
      }
    });
    const approvPopoverOptions: any = {
      message: '담당자를 선택하세요.',
      cssClass: 'select-pop'
    };
    function managerlines(e: any) {
      form.erpuser2 = e.target.value;

    }
    return { form, updateAproData, isConnected, approvPopoverOptions, managerlines, onIonViewWillEnter, managerInfo }
  }
});

