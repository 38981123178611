<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>1차승인</ion-title>
      <ion-button slot="end" @click="closeModal" color="medium" fill="clear">닫기</ion-button>
    </ion-toolbar>
  </ion-header>
  <ion-content :fullscreen="true">
    <ion-item>
      <ion-label position="floating">2차승인자</ion-label>
      <ion-select :interface-options="approvPopoverOptions" interface="popover" placeholder="눌러서 선택"
        :value="form.erpuser2" @ionChange="managerlines($event)">
        <ion-select-option v-for="item in managerInfo" :key="item.userName" :value="item.userName">
          {{
            item.userName
          }}
        </ion-select-option>
      </ion-select>
    </ion-item>
    <ion-footer>
      <div class='center-button'>
        <ion-button class="record-btn" color="medium" @click="submit(form.erpuser2)">승인</ion-button>
      </div>
    </ion-footer>
  </ion-content>
</template>

<script lang="ts">
import { useManage } from '@/api/services/manage';
import { IonContent, IonHeader, IonTitle, IonToolbar, IonItem, IonLabel, IonButton, modalController, IonFooter, IonSelect, IonSelectOption, onIonViewWillEnter } from "@ionic/vue";
import { defineComponent, reactive, PropType, onMounted } from "vue";
import { UserAppr } from '@/api/services/manage';
export default defineComponent({

  name: "Modal",
  components: { IonContent, IonHeader, IonTitle, IonToolbar, IonItem, IonLabel, IonButton, IonFooter, IonSelect, IonSelectOption }
  ,
  props: {
    apro: {
      type: Object as PropType<UserAppr>
    }
  },
  methods: {
    closeModal() {
      return modalController.dismiss(null, 'cancel');
    },
    submit(userName: any) {
      // console.log(salenm);
      return modalController.dismiss(userName, 'confirm');
    },
    clear() {
      // console.log(salenm);
      return modalController.dismiss(null, 'clear');
    },

  },
  setup(props) {
    const { isConnected, updateAproData, fetchMangerInfo, managerInfo } = useManage();
    fetchMangerInfo();
    const form = reactive({
      erpuser2: '',
      register: props.apro.userId
    });
    onMounted(() => {
      if (managerInfo.value[0]) {
        const adgb2 = managerInfo.value[0];
        form.erpuser2 = adgb2.userName;
      }
    });
    const approvPopoverOptions: any = {
      message: '담당자를 선택하세요.',
      cssClass: 'select-pop'
    };
    function managerlines(e: any) {
      form.erpuser2 = e.target.value;

    }
    return { form, updateAproData, isConnected, approvPopoverOptions, managerlines, onIonViewWillEnter, managerInfo }
  }
});

</script>

<style>
.center-button {
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 50px;
  height: 20%;
}

.record-btn {
  height: 40px;
  width: 120px;
}
</style>