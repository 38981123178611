import { Ref, ref } from 'vue';
import { $api, $loading } from '@/api/index';
import { toast } from '@/utils/toast';
import { modalController } from '@ionic/vue';

export interface UserAppr {
  userId: string;
  userName: string;
  userSano: string;
  userPriv: string;
  gubn_nm: string;
}
export type MangerInfo = {
  userName: string;
};
export function useManage() {
  const isConnected = ref(true);
  const items: Ref<UserAppr[]> = ref([]);
  const managerInfo: Ref<MangerInfo[]> = ref([]);
  const LIMIT = 10;
  const page = ref(1);
  const next = ref(LIMIT);

 async function fetchFinalDatas() {
    await $api(`/user/approvalFinal/?page=${page.value}&limit=${LIMIT}`, 'GET')
      .then((res: any) => {
        items.value = res.data;
        isConnected.value = true;
      })
      .catch(() => {
        isConnected.value = false;
      });
  }
  async function fetchApplyDatas() { 
    await $api(`/user/appr/`, 'GET')
      .then((res: any) => {
        items.value = res.data;
        isConnected.value = true;
      })
      .catch(() => {
        isConnected.value = false;
      });
  }

  async function fetchNextDatas() {
    await $api(`/user/approvalFinal/?page=${page.value}&limit=${LIMIT}`, 'get').then(
      (res: any) => {
        items.value = items.value.concat(res.data);
        next.value = res.data.length;
      }
    );
  }
  async function refreshDatas() {
    await $api(`/user/approvalFinal/?page=${page.value}&limit=${LIMIT}`, 'get').then(
      (res: any) => {
        items.value = res.data;
        next.value = res.data.length;
      }
    );
  }

  function getNextPage(): void {
    page.value += 1;
  }
  function setInitPage(): void {
    page.value = 1;
    next.value = LIMIT;
  }

  async function updateData(userid: string) {
    await $loading(`/user/approvalFinal?userid=${userid}`, 'post').then(
      (res: any) => {
        if (res.status == 200) {
          toast('승인 완료');
        }
      }
    );
  }
  async function updateAproData(userid: string, erpuser: string) {
    await $loading(`/user/approval?userid=${userid}&erpuser=${erpuser}`, 'post').then(
      (res: any) => {
        if (res.status == 200) {
          toast('승인 완료');
          //fetchApplyDatas();
          items.value = res.data;
          modalController.dismiss();
        }
      }
    ).catch(err => {
      if (err.response.status == 406) {   
        alert(err.response.data.message);
      } else if (err.response.status == 500) {
        toast(
          '내부서버의 오류로 인해 현재 서비스의 상태가 원할하지 않습니다. 시스템 관리자에게 문의하시기 바랍니다.',
          5000
        );
      }
    });
  }
  async function fetchMangerInfo() {
    await $api(`/user/managerInfo/`, 'GET')
      .then((res: any) => {
        managerInfo.value = res.data;
      })
  }
  return {
    LIMIT,
    next,
    items,
    isConnected,
    fetchApplyDatas,
    refreshDatas,
    fetchNextDatas,
    getNextPage,
    setInitPage,
    updateData,
    fetchFinalDatas,
    updateAproData,
    fetchMangerInfo,
    managerInfo
  };
}
